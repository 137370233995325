import React from "react";
// import { useTranslation } from 'react-i18next';
import "../assessments.css";
import cardio from "../../../../assets/cardio.png";
import mendurance from "../../../../assets/mendurance.png";
import mstrength from "../../../../assets/mstrength.png";
import sbalance1 from "../../../../assets/sbalance1.png";
import squats from "../../../../assets/squats.png";
import ninja from "../../../../assets/ninja.png";
import { ArcherContainer, ArcherElement } from "react-archer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

// Debugging Wrapper Component
const DebugWrapper = ({ children, id }) => {
  console.log(`Rendering DebugWrapper for ${id}`);
  return (
    <ErrorBoundary>
      {React.Children.map(children, (child, index) => {
        console.log(`Child ${index} of ${id}:`, child);
        return child;
      })}
    </ErrorBoundary>
  );
};

// Debugged ArcherElement Component
const DebuggedArcherElement = (props) => {
  console.log(`Rendering DebuggedArcherElement with id: ${props.id}`);
  return (
    <ErrorBoundary>
      <ArcherElement {...props}>
        {React.Children.only(props.children)}
      </ArcherElement>
    </ErrorBoundary>
  );
};

export const AssessmentPath = () => {
  console.log("Rendering AssessmentPath");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:900px)");

  const handleExerciseClick = React.useCallback(
    (iteration) => {
      return () => {
        console.log(`Exercise clicked with iteration: ${iteration}`);
        dispatch({
          type: "SET-ITER-VAlUES",
          payload: {
            iteration: iteration,
            overviewRedirect: null,
            exerciseIds: null,
          },
        });
        navigate("/demos/exhibition_assessment");
      };
    },
    [dispatch, navigate]
  );

  const renderExerciseElement = React.useCallback(
    (id, relations, onClick, title, imgSrc) => (
      <DebuggedArcherElement id={id} relations={relations}>
        <div className="img_container" onClick={onClick}>
          <div>{title}</div>
          <img src={imgSrc} alt={title} />
        </div>
      </DebuggedArcherElement>
    ),
    []
  );

  const mobileLayout = (
    <div className="assessment-cont">
      <div className="assessment-path">
        <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
          <div
            style={{
              display: "flex",
              height: "50vh",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="first_row">
              {renderExerciseElement(
                "root",
                [
                  {
                    targetId: "sb2",
                    targetAnchor: "top",
                    sourceAnchor: "bottom",
                    style: { endMarker: false },
                  },
                ],
                handleExerciseClick(0),
                "Squats",
                squats
              )}
              {renderExerciseElement(
                "mstren",
                [],
                handleExerciseClick(2),
                "Muscular Strength",
                mstrength
              )}
            </div>
            <div className="first_row" style={{ justifyContent: "center" }}>
              {renderExerciseElement(
                "sb2",
                [
                  {
                    targetId: "cdio",
                    targetAnchor: "bottom",
                    sourceAnchor: "top",
                  },
                ],
                handleExerciseClick(4),
                "Static Balance 2",
                ninja
              )}
            </div>
          </div>
        </ArcherContainer>
      </div>
      <div className="assessment-path">
        <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
          <div
            style={{
              display: "flex",
              height: "50vh",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="first_row">
              {renderExerciseElement(
                "mend",
                [
                  {
                    targetId: "cdio",
                    targetAnchor: "top",
                    sourceAnchor: "bottom",
                    style: { endMarker: false },
                  },
                ],
                handleExerciseClick(1),
                "Muscular Endurance",
                mendurance
              )}
              {renderExerciseElement(
                "sb1",
                [],
                handleExerciseClick(3),
                "Static Balance 1",
                sbalance1
              )}
            </div>
            <div className="first_row" style={{ justifyContent: "center" }}>
              {renderExerciseElement(
                "cdio",
                [
                  {
                    targetId: "sb2",
                    targetAnchor: "bottom",
                    sourceAnchor: "top",
                    style: { endMarker: false },
                  },
                ],
                handleExerciseClick(5),
                "Cardio Vascular",
                cardio
              )}
            </div>
          </div>
        </ArcherContainer>
      </div>
    </div>
  );

  const desktopLayout = (
    <div
      className="top-left article"
      style={{
        padding: "10% 4px",
        height: "80vh",
        width: "auto",
        marginTop: "1em",
      }}
    >
      <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
        <div
          style={{
            display: "flex",
            height: "60vh",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="first_row">
            {renderExerciseElement(
              "root",
              [
                {
                  targetId: "mend",
                  targetAnchor: "top",
                  sourceAnchor: "bottom",
                  style: { endMarker: false },
                },
              ],
              handleExerciseClick(0),
              "Squats",
              squats
            )}
            {renderExerciseElement(
              "sb1",
              [
                {
                  targetId: "cardio",
                  targetAnchor: "top",
                  sourceAnchor: "bottom",
                  style: { endMarker: false },
                },
              ],
              handleExerciseClick(2),
              "Static Balance",
              sbalance1
            )}
            {renderExerciseElement(
              "sb2",
              [
                {
                  targetId: "cardio",
                  targetAnchor: "top",
                  sourceAnchor: "bottom",
                  style: { endMarker: false },
                },
              ],
              handleExerciseClick(4),
              "Fruit Ninja",
              ninja
            )}
          </div>
          <div className="first_row" style={{ justifyContent: "center" }}>
            {renderExerciseElement(
              "mend",
              [
                {
                  targetId: "sb1",
                  targetAnchor: "bottom",
                  sourceAnchor: "top",
                  style: { endMarker: false },
                },
              ],
              handleExerciseClick(1),
              "Muscular Endurance",
              mendurance
            )}
            {renderExerciseElement(
              "cardio",
              [],
              handleExerciseClick(3),
              "Cardio Vascular",
              cardio
            )}
          </div>
        </div>
      </ArcherContainer>
    </div>
  );

  return (
    <ErrorBoundary>
      <DebugWrapper id="AssessmentPath-root">
        {matches ? mobileLayout : desktopLayout}
      </DebugWrapper>
    </ErrorBoundary>
  );
};
